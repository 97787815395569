import './main.css'
import { Elm } from './Main.elm'
import * as serviceWorker from './serviceWorker'
import SimplePeer from 'simple-peer'
import copy from 'clipboard-copy'
import { v4 as uuidv4 } from 'uuid'

const app = Elm.Main.init({
  node: document.getElementById('root')
});

setUpAppleSSO()

function setUpAppleSSO () {
  document.addEventListener('AppleIDSignInOnSuccess', (data) => {
    console.log('AppleSsoSuccess', data)
    app.ports.appleSsoSuccess.send(data)
  })

  document.addEventListener('AppleIDSignInOnFailure', (error) => {
    console.log('AppleSsoFailure', error)
    app.ports.appleSsoFailure.send(error)
  })

  AppleID.auth.init({
      clientId : 'com.fernandomrtnz.apalos.appleSso',
      scope : 'name',
      redirectURI : 'http://apalos.fernandomrtnz.com/redirect',
      state : 'login',
      nonce : uuidv4(),
      usePopup : true
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
